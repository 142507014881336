// src/main.jsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { registerServiceWorker } from './serviceWorkerRegistration'; // Import the registration function

const container = document.getElementById('root');
const root = createRoot(container); // Create root using React 18 API

// Register the Service Worker and wait for it to be ready
registerServiceWorker()
  .then(() => {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
    
    // Optionally, you can also send a message to the service worker if needed
  })
  .catch((error) => {
    console.error('Service Worker registration failed:', error);
    // Render the app even if service worker registration fails
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  });
