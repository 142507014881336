// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 dark:bg-gray-800 text-center py-4 mt-8">
      <p className="text-gray-600 dark:text-gray-400">
      Created with 💚 | Nos+ 2024
      </p>
    </footer>
  );
};

export default Footer;