import React from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline'; // Import Heroicons for sun and moon

const DarkModeToggle = ({ darkMode, setDarkMode }) => {
  // Toggle dark mode on and off
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <button
      onClick={toggleDarkMode}
      className="focus:outline-none flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 transition-colors duration-300"
      aria-label="Toggle Dark Mode"
    >
      {darkMode ? (
        <SunIcon className="w-6 h-6 text-yellow-400" /> // Sun icon for dark mode
      ) : (
        <MoonIcon className="w-6 h-6 text-gray-500" /> // Moon icon for light mode
      )}
    </button>
  );
};

export default DarkModeToggle;
