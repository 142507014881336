// src/context/ShareModalContext.jsx

import React, { createContext, useState } from 'react';

// Create the context
export const ShareModalContext = createContext();

// Create a provider component
export const ShareModalProvider = ({ children }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [currentRenderComponent, setCurrentRenderComponent] = useState(null);

  // Function to open the ShareModal with a specific component
  const openShareModal = (renderComponent) => {
    setCurrentRenderComponent(() => renderComponent);
    setIsShareModalOpen(true);
  };

  // Function to close the ShareModal
  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setCurrentRenderComponent(null);
  };

  // Functions to handle export status
  const startExport = () => setIsExporting(true);
  const finishExport = () => setIsExporting(false);

  return (
    <ShareModalContext.Provider
      value={{
        isShareModalOpen,
        openShareModal,
        closeShareModal,
        isExporting,
        startExport,
        finishExport,
        currentRenderComponent, // Ensure this is provided
      }}
    >
      {children}
    </ShareModalContext.Provider>
  );
};
