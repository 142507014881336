// frontend/src/services/api.js

import axios from "axios";

// Set the base URL for the API
const API_URL = "https://api.nos.plus/api"; // Use 'http://localhost:4000/api' during development
const API_BASE_URL = "https://api.nos.plus/api"; // Use 'http://localhost:4000/api' during development

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Request interceptor to add JWT token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Retrieve token from localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Utility to build params for requests
const buildParams = (overrides = {}) => {
  return {
    page: 1,
    entries_per_page: 20,
    sort_column: "date",
    sort_order: "DESC",
    ...overrides, // Merge with specific overrides passed from API functions
  };
};

// Generalized function to perform GET requests with params
const fetchFromAPI = async (endpoint, params = {}) => {
  try {
    // console.log(`Fetching from ${endpoint} with params:`, params);
    const response = await axiosInstance.get(endpoint, { params });
    // console.log(`Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching from ${endpoint}:`, error);
    throw error;
  }
};

// Define all API functions with 'alert' prefix
export const alertGetStakingDappStats = () =>
  axios.get(`${API_BASE_URL}/v2/staking_stats`);
export const alertGetNosanaStats = () =>
  axios.get(`${API_BASE_URL}/v2/nosana_volume_stats`);
export const alertGetNosanaHoldersStats = () =>
  axios.get(`${API_BASE_URL}/v2/nosana_holders_stats`);
export const alertGetRaydiumPoolStats = () =>
  axios.get(`${API_BASE_URL}/v2/raydium_stats`);
export const alertGetStakingBalanceStats = () =>
  axios.get(`${API_BASE_URL}/v2/nos_staking_stats`);
export const alertGetWalletDistributionsStats = () =>
  axios.get(`${API_BASE_URL}/v2/wallet_distributions_stats`);
export const alertGetNosanaPriceStats = () =>
  axios.get(`${API_BASE_URL}/v2/nosana_prices_stats`);
export const alertGetStakingSummariesStats = () =>
  axios.get(`${API_BASE_URL}/v2/staking_summaries/stats`);
export const alertGetTokenHoldersStats = () =>
  axios.get(`${API_BASE_URL}/v2/token_holders_stats`);
export const alertGetStakingSummariesAccountsStats = () =>
  axios.get(`${API_BASE_URL}/v2/staking_summaries_accounts/stats`);

// Volume Endpoints

// Fetch Nosana Volume Table (ungrouped table data with filters)
export const getNosanaVolumeTable = async (
  page = 1,
  isLive = false,
  entriesPerPage = 20,
  sortColumn = "date",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minVolume = 0,
  maxVolume = Number.MAX_SAFE_INTEGER
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    start_date: startDate,
    end_date: endDate,
    min_volume: minVolume,
    max_volume: maxVolume,
  });
  // console.log('API call to /v2/nosana_volume_table with params:', params);
  return fetchFromAPI("/v2/nosana_volume_table", params);
};

// Fetch Nosana Volume V2 (grouped chart data with filters)
export const getNosanaVolumeV2 = async (
  page = 1,
  isLive = false,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  minVolume = 0,
  maxVolume = Number.MAX_SAFE_INTEGER,
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_volume: minVolume,
    max_volume: maxVolume,
  });
  // console.log('API call to /v2/nosana_volume with params:', params);
  return fetchFromAPI("/v2/nosana_volume", params);
};

// Fetch Nosana Volume Stats
export const getNosanaStats = async () => {
  return fetchFromAPI("/v2/nosana_volume_stats");
};

// Fetch Nosana Volume Stats
export const getNosanaVolumeStats = async () => {
  return fetchFromAPI("/v2/nosana_volume_stats");
};

// Holders Endpoints

// Fetch Nosana Holders Table (ungrouped table data with filters)
export const getNosanaHoldersTable = async (
  page = 1,
  isLive = false,
  entriesPerPage = 20,
  sortColumn = "date",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minHolders = 0,
  maxHolders = 1000000
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    start_date: startDate,
    end_date: endDate,
    min_holders: minHolders,
    max_holders: maxHolders,
  });
  // console.log('API call to /v2/nosana_holders_table with params:', params);
  return fetchFromAPI("/v2/nosana_holders_table", params);
};

// Fetch Nosana Holders V2 (grouped chart data with filters)
export const getNosanaHoldersV2 = async (
  page = 1,
  isLive = false,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  minHolders = 0,
  maxHolders = 1000000,
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_holders: minHolders,
    max_holders: maxHolders,
  });
  // console.log('API call to /v2/nosana_holders with params:', params);
  return fetchFromAPI("/v2/nosana_holders", params);
};

// Fetch Nosana Holders Stats
export const getNosanaHoldersStats = async () => {
  return fetchFromAPI("/v2/nosana_holders_stats");
};

// Miscellaneous Endpoints

// Fetch Nosana Daily Update
export const getNosDailyUpdate = async (date) => {
  const params = { date };
  // console.log('API call to /nos_daily_update with params:', params);
  return fetchFromAPI("/nos_daily_update", params);
};

// Staking Endpoints

// Fetch Staking Table (ungrouped table data with APR and xNOS filters)
export const getStakingDappTable = async (
  page = 1,
  isLive = false,
  entriesPerPage = 20,
  sortColumn = "date",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minAPR = 0,
  maxAPR = 100,
  minXnos = 0,
  maxXnos = 100000000
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    start_date: startDate,
    end_date: endDate,
    min_apr: minAPR,
    max_apr: maxAPR !== Infinity ? maxAPR : 100, // Handle Infinity
    min_xnos: minXnos,
    max_xnos: maxXnos !== Infinity ? maxXnos : 100000000, // Handle Infinity
  });
  // console.log('API call to /v2/staking_table with params:', params);
  return fetchFromAPI("/v2/staking_table", params);
};

// Fetch Staking Data (grouped chart data with APR and xNOS filters)
export const getStakingDappData = async (
  page = 1,
  isLive = false,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  minAPR = 0,
  maxAPR = Number.MAX_SAFE_INTEGER,
  minXnos = 0,
  maxXnos = Number.MAX_SAFE_INTEGER,
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn, // Use the parameter
    sort_order: sortOrder, // Use the parameter
    live: isLive ? 1 : 0,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_apr: minAPR,
    max_apr: maxAPR !== Infinity ? maxAPR : 100, // Handle Infinity
    min_xnos: minXnos,
    max_xnos: maxXnos !== Infinity ? maxXnos : 100000000, // Handle Infinity
  });
  // console.log('API call to /v2/staking_data with params:', params);
  return fetchFromAPI("/v2/staking_data", params);
};

// Fetch Staking Stats
export const getStakingDappStats = async () => {
  return fetchFromAPI("/v2/staking_stats");
};


// Nosana Prices Table

export const getNosanaPricesTable = async (
  page = 1,
  isLive = false,
  entriesPerPage = 20,
  sortColumn = "date",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minPrice = 0,
  maxPrice = Number.MAX_SAFE_INTEGER
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    start_date: startDate,
    end_date: endDate,
    min_price: minPrice,
    max_price: maxPrice,
  });
  // console.log('API call to /v2/nosana_prices_table with params:', params);
  return fetchFromAPI("/v2/nosana_prices_table", params);
};

// Fetch Nosana Prices V2 (grouped chart data with filters)
export const getNosanaPricesV2 = async (
  page = 1,
  isLive = false,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  minPrice = 0,
  maxPrice = Number.MAX_SAFE_INTEGER,
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage, // Use the parameter
    sort_column: sortColumn, // Use the parameter
    sort_order: sortOrder, // Use the parameter
    live: isLive ? 1 : 0,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_price: minPrice,
    max_price: maxPrice,
  });
  // console.log('API call to /v2/nosana_prices with params:', params);
  return fetchFromAPI("/v2/nosana_prices", params);
};

// Fetch Nosana Prices Stats
export const getNosanaPriceStats = async () => {
  return fetchFromAPI("/v2/nosana_prices_stats");
};

// Raydium Pool Endpoints

// Fetch Raydium Pool Table (ungrouped table data with filters)
export const getRaydiumPoolTable = async (
  page = 1,
  isLive = false,
  entriesPerPage = 20,
  sortColumn = "date",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minLiquidity = 0,
  maxLiquidity = Number.MAX_SAFE_INTEGER,
  minAPR = 0,
  maxAPR = Number.MAX_SAFE_INTEGER
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    start_date: startDate,
    end_date: endDate,
    min_liquidity: minLiquidity,
    max_liquidity: maxLiquidity,
    min_apr: minAPR,
    max_apr: maxAPR,
  });
  return fetchFromAPI("/v2/raydium_pool_table", params);
};

// Fetch Raydium Pool Data (grouped chart data with filters)
export const getRaydiumPoolData = async (
  page = 1,
  isLive = false,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  minLiquidity = 0,
  maxLiquidity = Number.MAX_SAFE_INTEGER,
  minAPR = 0,
  maxAPR = Number.MAX_SAFE_INTEGER,
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn, // Use the parameter
    sort_order: sortOrder, // Use the parameter
    live: isLive ? 1 : 0,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_liquidity: minLiquidity,
    max_liquidity: maxLiquidity,
    min_apr: minAPR,
    max_apr: maxAPR,
  });
  return fetchFromAPI("/v2/raydium_pool", params);
};

// Fetch Raydium Pool Stats
export const getRaydiumPoolStats = async () => {
  return fetchFromAPI("/v2/raydium_stats");
};

// Staking Balance Endpoints

// Fetch Staking Balance Table (ungrouped table data with filters)
export const getStakingBalanceTable = async (
  page = 1,
  isLive = false,
  entriesPerPage = 20,
  sortColumn = "date",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minBalance = 0,
  maxBalance = Number.MAX_SAFE_INTEGER
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    live: isLive ? 1 : 0,
    start_date: startDate,
    end_date: endDate,
    min_balance: minBalance,
    max_balance: maxBalance,
  });
  // console.log('API call to /v2/nos_staking_balances_table with params:', params);
  return fetchFromAPI("/v2/nos_staking_balances_table", params);
};

// Fetch Staking Balance Data (grouped chart data with filters)
export const getStakingBalanceData = async (
  page = 1,
  isLive = false,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  minBalance = 0,
  maxBalance = Number.MAX_SAFE_INTEGER,
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage, // Use the parameter
    sort_column: sortColumn, // Use the parameter
    sort_order: sortOrder, // Use the parameter
    live: isLive ? 1 : 0,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_balance: minBalance,
    max_balance: maxBalance,
  });
  // console.log('API call to /v2/nos_staking_balances with params:', params);
  return fetchFromAPI("/v2/nos_staking_balances", params);
};

// Fetch Staking Balance Stats
export const getStakingBalanceStats = async () => {
  return fetchFromAPI("/v2/nos_staking_stats");
};

// Wallet Distributions Endpoints

// Fetch Wallet Distributions Table
export const getWalletDistributionsTable = async (
  page = 1,
  entriesPerPage = 20,
  sortColumn = "timestamp",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  categoryFilterValues = {}
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    start_date: startDate,
    end_date: endDate,
    ...Object.keys(categoryFilterValues).reduce((acc, key) => {
      acc[`min_${key}`] = categoryFilterValues[key].min;
      acc[`max_${key}`] = categoryFilterValues[key].max === Infinity ? '' : categoryFilterValues[key].max;
      return acc;
    }, {}),
  });
  return fetchFromAPI("/v2/wallet_distributions", params);
};


// Fetch Wallet Distributions Chart Data (grouped chart data with filters)
export const getWalletDistributionsChart = async (
  timeframe = "DAYS",
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    timeframe,
    sort_column: sortColumn,
    sort_order: sortOrder,
  });
  // console.log('API call to /v2/wallet_distributions_chart with params:', params);
  return fetchFromAPI("/v2/wallet_distributions_chart", params);
};

// Fetch Wallet Distributions Stats
export const getWalletDistributionsStats = async () => {
  return fetchFromAPI("/v2/wallet_distributions_stats");
};

// Helper function to scale amounts by 1,000,000
const scaleAmount = (amount) => {
  return amount * 1000000;
};

// Staking Summaries Endpoints

/**
 * Fetch Staking Summaries (List)
 */
export const getStakingSummaries = async (
  page = 1,
  entriesPerPage = 20,
  sortColumn = "processed_at",
  sortOrder = "DESC",
  timeframe = "ALL",
  startDate = "",
  endDate = "",
  minStakingAmount = 0,
  maxStakingAmount = Number.MAX_SAFE_INTEGER,
  minUnstakingAmount = 0,
  maxUnstakingAmount = Number.MAX_SAFE_INTEGER,
  minStakingXnos = 0,
  maxStakingXnos = Number.MAX_SAFE_INTEGER,
  minUnstakingXnos = 0,
  maxUnstakingXnos = Number.MAX_SAFE_INTEGER
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    // Scale staking and unstaking amounts by 1,000,000
    min_staking_amount: scaleAmount(minStakingAmount),
    max_staking_amount: scaleAmount(maxStakingAmount),
    min_unstaking_amount: scaleAmount(minUnstakingAmount),
    max_unstaking_amount: scaleAmount(maxUnstakingAmount),
    // Leave act_unstaking_amount as is
    min_staking_xnos: minStakingXnos,
    max_staking_xnos: maxStakingXnos,
    min_unstaking_xnos: minUnstakingXnos,
    max_unstaking_xnos: maxUnstakingXnos,
    // **New Total Staking Accounts Filters**
    min_total_staking_accounts: parseInt(minStakingAmount) || 0,
    max_total_staking_accounts: parseInt(maxStakingAmount) || Number.MAX_SAFE_INTEGER,
  });
  // console.log('API call to /v2/staking_summaries with params:', params);
  return fetchFromAPI("/v2/staking_summaries", params);
};

/**
 * Fetch Staking Summaries Statistics
 */
export const getStakingSummariesStats = async (timeframe = "ALL") => {
  const params = { timeframe };
  // console.log('API call to /v2/staking_summaries/stats with params:', params);
  return fetchFromAPI("/v2/staking_summaries/stats", params);
};

/**
 * Fetch Staking Summaries Table Data
 */
export const getStakingSummariesTable = async (
  page = 1,
  entriesPerPage = 20,
  sortColumn = "processed_at",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minStakingAmount = 0,
  maxStakingAmount = Number.MAX_SAFE_INTEGER,
  minUnstakingAmount = 0,
  maxUnstakingAmount = Number.MAX_SAFE_INTEGER,
  minStakingXnos = 0,
  maxStakingXnos = Number.MAX_SAFE_INTEGER,
  minUnstakingXnos = 0,
  maxUnstakingXnos = Number.MAX_SAFE_INTEGER
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage, // Ensure snake_case
    sort_column: sortColumn, // Ensure snake_case
    sort_order: sortOrder, // Ensure snake_case
    start_date: startDate, // Ensure snake_case
    end_date: endDate, // Ensure snake_case
    min_staking_amount: scaleAmount(minStakingAmount),
    max_staking_amount: scaleAmount(maxStakingAmount),
    min_unstaking_amount: scaleAmount(minUnstakingAmount),
    max_unstaking_amount: scaleAmount(maxUnstakingAmount),
    // Leave act_unstaking_amount as is
    min_staking_xnos: minStakingXnos,
    max_staking_xnos: maxStakingXnos,
    min_unstaking_xnos: minUnstakingXnos,
    max_unstaking_xnos: maxUnstakingXnos,
    // **New Total Staking Accounts Filters**
    min_total_staking_accounts: parseInt(minStakingAmount) || 0,
    max_total_staking_accounts: parseInt(maxStakingAmount) || Number.MAX_SAFE_INTEGER,
  });
  return fetchFromAPI("/v2/staking_summaries/table", params);
};

/**
 * Fetch Staking Summaries Accounts (List)
 */
export const getStakingSummariesAccounts = async (
  page = 1,
  entriesPerPage = 20,
  sortColumn = "processed_at",
  sortOrder = "DESC",
  timeframe = "ALL",
  startDate = "",
  endDate = "",
  minStakingAccounts = 0,
  maxStakingAccounts = Number.MAX_SAFE_INTEGER,
  minUnstakingAccounts = 0,
  maxUnstakingAccounts = Number.MAX_SAFE_INTEGER,
  minTotalStakingAccounts = 0, // New Parameter
  maxTotalStakingAccounts = Number.MAX_SAFE_INTEGER // New Parameter
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
    timeframe,
    start_date: startDate,
    end_date: endDate,
    min_staking_accounts: minStakingAccounts,
    max_staking_accounts: maxStakingAccounts,
    min_unstaking_accounts: minUnstakingAccounts,
    max_unstaking_accounts: maxUnstakingAccounts,
    min_total_staking_accounts: minTotalStakingAccounts, // New Param
    max_total_staking_accounts: maxTotalStakingAccounts, // New Param
  });
  // console.log('API call to /v2/staking_summaries_accounts with params:', params);
  return fetchFromAPI("/v2/staking_summaries_accounts", params);
};

/**
 * Fetch Staking Summaries Accounts Statistics
 */
export const getStakingSummariesAccountsStats = async (timeframe = "ALL") => {
  const params = { timeframe };
  // console.log('API call to /v2/staking_summaries_accounts/stats with params:', params);
  return fetchFromAPI("/v2/staking_summaries_accounts/stats", params);
};

/**
 * Fetch Staking Summaries Accounts Table Data
 */
export const getStakingSummariesAccountsTable = async (
  page = 1,
  entriesPerPage = 20,
  sortColumn = "processed_at",
  sortOrder = "DESC",
  startDate = "",
  endDate = "",
  minStakingAccounts = 0,
  maxStakingAccounts = Number.MAX_SAFE_INTEGER,
  minUnstakingAccounts = 0,
  maxUnstakingAccounts = Number.MAX_SAFE_INTEGER,
  minTotalStakingAccounts = 0, // New Parameter
  maxTotalStakingAccounts = Number.MAX_SAFE_INTEGER // New Parameter
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage, // Ensure snake_case
    sort_column: sortColumn, // Ensure snake_case
    sort_order: sortOrder, // Ensure snake_case
    start_date: startDate, // Ensure snake_case
    end_date: endDate, // Ensure snake_case
    min_staking_accounts: minStakingAccounts,
    max_staking_accounts: maxStakingAccounts,
    min_unstaking_accounts: minUnstakingAccounts,
    max_unstaking_accounts: maxUnstakingAccounts,
    min_total_staking_accounts: minTotalStakingAccounts, // New Param
    max_total_staking_accounts: maxTotalStakingAccounts, // New Param
  });
  return fetchFromAPI("/v2/staking_summaries_accounts/table", params);
};

// Token Holders Endpoints

/**
 * Fetches token holders data based on the specified timeframe.
 */
export const getTokenHoldersV2 = async ({
  timeframe = "1W",
  page = 1,
  entriesPerPage = 30,
  sortColumn = "rank",
  sortOrder = "ASC",
}) => {
  const params = buildParams({
    timeframe,
    page,
    entries_per_page: entriesPerPage,
    sort_column: sortColumn,
    sort_order: sortOrder,
  });
  return fetchFromAPI("/v2/token_holders", params);
};

/**
 * Fetches token holders statistics.
 */
export const getTokenHoldersStats = async () => {
  return fetchFromAPI("/v2/token_holders_stats");
};

// Events Endpoints

/**
 * Fetch recent events (Public)
 */
export const getEvents = async () => {
  try {
    const response = await axiosInstance.get("/events");
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

/**
 * Add a new event (admin only)
 */
export const addEvent = async (eventData) => {
  try {
    const response = await axiosInstance.post("/events", eventData);
    return response.data;
  } catch (error) {
    console.error("Error adding event:", error);
    throw error;
  }
};

/**
 * Update an existing event (admin only)
 */
export const updateEvent = async (id, eventData) => {
  try {
    const response = await axiosInstance.put(`/events/${id}`, eventData);
    return response.data;
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
};

/**
 * Delete an event (admin only)
 */
export const deleteEvent = async (id) => {
  try {
    const response = await axiosInstance.delete(`/events/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
};

/**
 * Fetch Twitter Links
 */
export const getTwitterLinks = async () => {
  try {
    const response = await axiosInstance.get("/twitter-links");
    return response.data;
  } catch (error) {
    console.error("Error fetching Twitter links:", error);
    throw error;
  }
};

/**
 * User login
 */
export const loginUser = async (credentials) => {
  try {
    const response = await axiosInstance.post("/login", credentials);
    const { token } = response.data;
    if (token) {
      localStorage.setItem("token", token); // Store the token
    }
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

/**
 * User registration (Optional)
 */
export const registerUser = async (userData) => {
  try {
    const response = await axiosInstance.post("/register", userData);
    return response.data;
  } catch (error) {
    console.error("Error registering user:", error);
    throw error;
  }
};

// Wallet Distributions Endpoints
// Fetch Wallet Distributions Data
export const getWalletDistributionsData = async (
  page = 1,
  timeframe = "1Y",
  entriesPerPage = 20,
  startDate = "",
  endDate = "",
  categoryFilterValues = {},
  sortColumn = "date",
  sortOrder = "DESC"
) => {
  const params = buildParams({
    page,
    entries_per_page: entriesPerPage,
    timeframe,
    sort_column: sortColumn,
    sort_order: sortOrder,
    start_date: startDate,
    end_date: endDate,
    ...Object.keys(categoryFilterValues).reduce((acc, key) => {
      acc[`min_${key}`] = categoryFilterValues[key].min;
      acc[`max_${key}`] = categoryFilterValues[key].max === Infinity ? '' : categoryFilterValues[key].max;
      return acc;
    }, {}),
  });
  return fetchFromAPI("/v2/wallet_distributions_chart", params);
};

// New API function to fetch percentage changes
export const getStakingBalancePercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/nos_staking_balances_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching percentage changes:", error);
    throw error;
  }
};

// New API function to fetch percentage changes
export const getNosanaPricePercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/nosana_prices_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching price percentage changes:", error);
    throw error;
  }
};

// New API function to fetch staking percentage changes
export const getStakingPercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/staking_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching staking percentage changes:", error);
    throw error;
  }
};

export const getStakingSummariesPercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/staking_summaries_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching staking summaries percentage changes:",
      error
    );
    throw error;
  }
};

export const getStakingSummariesAccountsPercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/staking_summaries_accounts_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching staking summaries accounts percentage changes:",
      error
    );
    throw error;
  }
};

export const getNosanaVolumePercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/nosana_volume_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Nosana volume percentage changes:",
      error
    );
    throw error;
  }
};

export const getNosanaHoldersPercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/nosana_holders_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Nosana holders percentage changes:",
      error
    );
    throw error;
  }
};

// New API function to fetch wallet distributions percentage changes
export const getWalletDistributionsPercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/wallet_distributions_percentage_changes`
    );
    // console.log("HALLLOO");
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching wallet distributions percentage changes:",
      error
    );
    throw error;
  }
};

// New API function to fetch Raydium Pool percentage changes
export const getRaydiumPercentageChanges = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/v2/raydium_percentage_changes`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Raydium percentage changes:", error);
    throw error;
  }
};

// Export all functions
export default {
  loginUser,
  registerUser,
  addEvent,
  updateEvent,
  deleteEvent,
  getEvents,
  getTwitterLinks,
  getNosanaVolumeTable,
  getNosanaVolumeV2,
  getNosanaStats,
  getNosanaHoldersTable,
  getNosanaHoldersV2,
  getNosanaHoldersStats,
  getNosDailyUpdate,
  getStakingDappTable,
  getStakingDappData,
  getStakingDappStats,
  getNosanaPricesTable,
  getNosanaPricesV2,
  getNosanaPriceStats,
  getRaydiumPoolTable,
  getRaydiumPoolData,
  getRaydiumPoolStats,
  getStakingBalanceTable,
  getStakingBalanceData,
  getStakingBalanceStats,
  getWalletDistributionsTable,
  getWalletDistributionsChart,
  getWalletDistributionsStats,
  getStakingSummaries,
  getStakingSummariesStats,
  getStakingSummariesTable,
  getStakingSummariesAccounts, // Updated Function
  getStakingSummariesAccountsStats, // Updated Function
  getStakingSummariesAccountsTable, // Updated Function
  getTokenHoldersV2,
  getTokenHoldersStats,
  getWalletDistributionsData,
  getStakingBalancePercentageChanges,
  getNosanaPricePercentageChanges,
  getStakingPercentageChanges,
  getStakingSummariesPercentageChanges,
  getStakingSummariesAccountsPercentageChanges,
  getNosanaVolumePercentageChanges,
  getNosanaHoldersPercentageChanges,
  getWalletDistributionsPercentageChanges,
  getRaydiumPercentageChanges,
  getNosanaVolumeStats,
  // ... other exports if any
};
