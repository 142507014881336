// src/serviceWorkerRegistration.js

export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    return new Promise((resolve, reject) => {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js') // Ensure the path is correct
          .then((registration) => {
            // console.log('Service Worker registered with scope:', registration.scope);

            // Listen for updates to the Service Worker
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (installingWorker == null) {
                return;
              }
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    // New content is available; inform the user if needed
                    // console.log('New content is available and will be used when all tabs for this page are closed.');
                  } else {
                    // Content is cached for offline use
                    // console.log('Content is cached for offline use.');
                  }
                }
              };
            };

            // Wait until the service worker is ready
            navigator.serviceWorker.ready
              .then(() => {
                // console.log('Service Worker is ready and controlling the page.');
                resolve();
              })
              .catch((error) => {
                // console.error('Service Worker failed to become ready:', error);
                reject(error);
              });
          })
          .catch((error) => {
            // console.error('Error during Service Worker registration:', error);
            reject(error);
          });
      });
    });
  } else {
    // console.warn('Service Workers are not supported in this browser.');
    return Promise.reject('Service Workers not supported');
  }
}
