// src/App.jsx

import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import DarkModeProvider from './context/DarkModeContext';
import { DarkModeContext } from './context/DarkModeContext';
import { ShareModalProvider } from './context/ShareModalContext';

// Lazy load your page components
const HomePage = lazy(() => import('./pages/HomePage'));
const NosanaPricesPage = lazy(() => import('./pages/NosanaPricesPage'));
const NosanaVolumePage = lazy(() => import('./pages/NosanaVolumePage'));
const NosanaHoldersPage = lazy(() => import('./pages/NosanaHoldersPage'));
const StakingDappPage = lazy(() => import('./pages/StakingDappPage'));
const StakingBalancePage = lazy(() => import('./pages/StakingBalancePage'));
const WalletAnalysisPage = lazy(() => import('./pages/WalletAnalysisPage'));
const RaydiumPoolPage = lazy(() => import('./pages/RaydiumPoolPage'));
const StakingSummariesPage = lazy(() => import('./pages/StakingSummariesPage'));
const StakingSummariesAccountsPage = lazy(() => import('./pages/StakingSummariesAccountsPage'));
const RichListPage = lazy(() => import('./pages/RichListPage'));
const AdminEvents = lazy(() => import('./pages/AdminEvents'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SqlDataPage = lazy(() => import('./pages/SqlDataPage'));

// Import React Toastify components and styles
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import renamed API functions
import {
  alertGetStakingDappStats,
  alertGetNosanaStats,
  alertGetNosanaHoldersStats,
  alertGetRaydiumPoolStats,
  alertGetStakingBalanceStats,
  alertGetWalletDistributionsStats,
  alertGetNosanaPriceStats,
  alertGetStakingSummariesStats,
  alertGetTokenHoldersStats,
  alertGetStakingSummariesAccountsStats,
} from './services/api';

// Import utility functions
import { differenceInMonths } from 'date-fns';

// Import LoadingSpinner component
import LoadingSpinner from './components/LoadingSpinner';

const getDarkModeFromLocalStorage = () => {
  const savedTheme = localStorage.getItem('darkMode');
  return savedTheme === null ? true : savedTheme === 'true';
};

// Constants and Utility Functions for Derived Calculations
const TOTAL_NOS = 100000000; // 100 million tokens

const distributions = {
  publicSale: 0.03, // 3%
  airdrop: 0.05,    // 5%
  liquidity: 0.10,  // 10%
  backers: 0.17,    // 17% (10% immediate, 90% linear over 9 months)
  mining: 0.20,     // 20% linear over 24 months
  team: 0.20,       // 20% linear over 48 months
  company: 0.25,    // 25% (10% immediate, 90% linear over 36 months)
};

const startDate = new Date('2022-01-17T00:00:00Z');
const endDate = new Date('2026-01-17T00:00:00Z');

const calculateCirculatingSupply = () => {
  const now = new Date();

  // If current date is before start date, circulating supply is 0
  if (now < startDate) {
    return 0;
  }

  // If current date is after end date, circulating supply is total supply
  if (now > endDate) {
    return TOTAL_NOS;
  }

  let totalCirculating = 0;

  // Immediate Release Pools
  totalCirculating += TOTAL_NOS * distributions.publicSale;
  totalCirculating += TOTAL_NOS * distributions.airdrop;
  totalCirculating += TOTAL_NOS * distributions.liquidity;

  // Backers (10% immediate, 90% linear over 9 months)
  const backersImmediate = TOTAL_NOS * distributions.backers * 0.10;
  const backersLinear = TOTAL_NOS * distributions.backers * 0.90;
  const backersReleaseDurationMonths = 9;
  const backersElapsedMonths = differenceInMonths(now, startDate);

  if (backersElapsedMonths >= backersReleaseDurationMonths) {
    totalCirculating += backersLinear;
  } else if (backersElapsedMonths > 0) {
    totalCirculating += (backersLinear / backersReleaseDurationMonths) * backersElapsedMonths;
  }
  totalCirculating += backersImmediate;

  // Mining (Linear over 24 months)
  const miningReleaseDurationMonths = 24;
  const miningElapsedMonths = differenceInMonths(now, startDate);

  if (miningElapsedMonths >= miningReleaseDurationMonths) {
    totalCirculating += TOTAL_NOS * distributions.mining;
  } else if (miningElapsedMonths > 0) {
    totalCirculating += (TOTAL_NOS * distributions.mining / miningReleaseDurationMonths) * miningElapsedMonths;
  }

  // Team (Linear over 48 months)
  const teamReleaseDurationMonths = 48;
  const teamElapsedMonths = differenceInMonths(now, startDate);

  if (teamElapsedMonths >= teamReleaseDurationMonths) {
    totalCirculating += TOTAL_NOS * distributions.team;
  } else if (teamElapsedMonths > 0) {
    totalCirculating += (TOTAL_NOS * distributions.team / teamReleaseDurationMonths) * teamElapsedMonths;
  }

  // Company (10% immediate, 90% linear over 36 months)
  const companyImmediate = TOTAL_NOS * distributions.company * 0.10;
  const companyLinear = TOTAL_NOS * distributions.company * 0.90;
  const companyReleaseDurationMonths = 36;
  const companyElapsedMonths = differenceInMonths(now, startDate);

  if (companyElapsedMonths >= companyReleaseDurationMonths) {
    totalCirculating += companyLinear;
  } else if (companyElapsedMonths > 0) {
    totalCirculating += (companyLinear / companyReleaseDurationMonths) * companyElapsedMonths;
  }
  totalCirculating += companyImmediate;

  // Ensure total does not exceed TOTAL_NOS
  totalCirculating = Math.min(totalCirculating, TOTAL_NOS);

  return Math.floor(totalCirculating);
};

// TVL Calculation
const calculateTVL = (stakingBalance, price) => {
  return stakingBalance * price;
};

// Market Cap Calculation
const calculateMarketCap = (circulatingSupply, price) => {
  return circulatingSupply * price;
};

// Fully Diluted Market Cap Calculation
const calculateFDMC = (totalSupply, price) => {
  return totalSupply * price;
};

// Function to remove an alert by ID
const removeAlertById = async (id) => {
  try {
    const db = await openDatabase();
    const transaction = db.transaction('alerts', 'readwrite');
    const store = transaction.objectStore('alerts');
    const request = store.delete(id);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        // console.log('Alert removed with ID:', id);
        resolve();
      };

      request.onerror = (event) => {
        console.error('Error removing alert:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('removeAlertById Error:', error);
  }
};

// Function to open the IndexedDB
const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('alerts-db', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('alerts')) {
        const store = db.createObjectStore('alerts', {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('cardId', 'cardId', { unique: false });
        // console.log(`Object store 'alerts' created.`);
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      // console.log('IndexedDB opened successfully.');
      resolve(db);
    };

    request.onerror = (event) => {
      console.error('Error opening IndexedDB:', event.target.error);
      reject(event.target.error);
    };
  });
};

// Function to get all alerts
const getAllAlerts = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDatabase();
      const transaction = db.transaction('alerts', 'readonly');
      const store = transaction.objectStore('alerts');
      const request = store.getAll();

      request.onsuccess = (event) => {
        const alerts = event.target.result;
        // console.log('Fetched alerts:', alerts);
        resolve(alerts);
      };

      request.onerror = (event) => {
        console.error('Error fetching alerts:', event.target.error);
        reject(event.target.error);
      };
    } catch (error) {
      console.error('getAllAlerts Error:', error);
      reject(error);
    }
  });
};

// Function to check alerts and send notifications
const checkAlerts = async (cachedData) => {
  // console.log('Checking alerts...');
  const alerts = await getAllAlerts();

  for (const alert of alerts) {
    const { id, cardId, title, value: alertValue, condition } = alert;
    let currentValue = null;

    try {
      switch (cardId) {
        case 'priceStats':
          if (cachedData.priceStats) {
            currentValue = parseFloat(cachedData.priceStats.current_price);
          }
          break;
        case 'holdersStats':
          if (cachedData.holdersStats) {
            currentValue = parseFloat(cachedData.holdersStats.current_holders);
          }
          break;
        case 'volumeStats':
          if (cachedData.volumeStats) {
            currentValue = parseFloat(cachedData.volumeStats.current_volume);
          }
          break;
        case 'stakingBalanceStats':
          if (cachedData.stakingBalanceStats) {
            currentValue = parseFloat(cachedData.stakingBalanceStats.current_balance);
          }
          break;
        case 'stakingStats':
          if (cachedData.stakingStats) {
            currentValue = parseFloat(cachedData.stakingStats.current_apr); // Assuming APR is the metric
          }
          break;
        case 'raydiumPoolStats':
          if (cachedData.raydiumPoolStats) {
            currentValue = parseFloat(cachedData.raydiumPoolStats.current_apr); // Assuming APR as metric
          }
          break;
        case 'stakingSummariesStats':
          if (cachedData.stakingSummariesStats) {
            currentValue = parseFloat(cachedData.stakingSummariesStats.current_act_unstaking_amount);
          }
          break;
        case 'tokenHoldersStats':
          if (cachedData.tokenHoldersStats && cachedData.tokenHoldersStats.current_total) {
            currentValue = parseFloat(cachedData.tokenHoldersStats.current_total.amount);
          }
          break;
        case 'stakingAccountsStats':
          if (cachedData.stakingAccountsStats) {
            // Determine if the alert is for stakers or unstakers based on title or additional data
            if (title.toLowerCase().includes('stakers')) {
              currentValue = parseFloat(cachedData.stakingAccountsStats.current_staking_accounts);
            } else if (title.toLowerCase().includes('unstakers')) {
              currentValue = parseFloat(cachedData.stakingAccountsStats.current_unstaking_accounts);
            }
          }
          break;
        case 'circulatingSupply':
          currentValue = calculateCirculatingSupply();
          break;
        case 'tvlStats':
          if (cachedData.stakingBalanceStats && cachedData.priceStats) {
            currentValue = calculateTVL(
              parseFloat(cachedData.stakingBalanceStats.current_balance),
              parseFloat(cachedData.priceStats.current_price)
            );
          }
          break;
        case 'mcStats':
          if (cachedData.priceStats) {
            const circulatingSupply = calculateCirculatingSupply();
            currentValue = calculateMarketCap(circulatingSupply, parseFloat(cachedData.priceStats.current_price));
          }
          break;
        case 'fdmcStats':
          if (cachedData.priceStats) {
            currentValue = calculateFDMC(TOTAL_NOS, parseFloat(cachedData.priceStats.current_price));
          }
          break;
        case 'unstakers':
          if (cachedData.stakingAccountsStats) {
            currentValue = parseFloat(cachedData.stakingAccountsStats.current_unstaking_accounts);
          }
          break;
        // Add more cases as needed for other cardIds
        default:
          console.warn(`No logic defined for cardId: ${cardId}`);
          continue;
      }

      // console.log(`Current value for '${title}' (cardId: ${cardId}):`, currentValue);
      // console.log(`Alert Value:`, alertValue);
      // console.log(`Condition:`, condition);

      if (currentValue === null || typeof currentValue !== 'number') {
        // console.log('Skipping alert due to invalid currentValue.');
        continue;
      }

      const conditionMet =
        (condition === 'above' && currentValue >= parseFloat(alertValue)) ||
        (condition === 'below' && currentValue <= parseFloat(alertValue));

      // console.log(`Condition met for alert ID ${id}:`, conditionMet);

      if (conditionMet) {
        // console.log(`Condition met. Sending notification for alert ID ${id}.`);
        // Send message to Service Worker to show notification
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'SHOW_NOTIFICATION',
            payload: {
              title: `🔔 Alert: ${title} ${condition} ${alertValue}`,
              body: `Current value is ${currentValue}`,
            },
          });
          // console.log(`Notification message sent for alert ID ${id}.`);
        } else {
          console.warn('Service Worker controller not found.');
        }

        // Remove the alert after triggering
        await removeAlertById(id);
      } else {
        // console.log(`Condition not met for alert ID ${id}. No notification sent.`);
      }
    } catch (error) {
      console.error(`Error checking alert for cardId ${cardId}:`, error);
    }
  }
};

const App = () => {
  const [darkMode, setDarkMode] = useState(getDarkModeFromLocalStorage);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  // Cached data to avoid multiple API calls
  const cachedData = {
    priceStats: null,
    holdersStats: null,
    volumeStats: null,
    stakingBalanceStats: null,
    stakingStats: null,
    raydiumPoolStats: null,
    stakingSummariesStats: null,
    tokenHoldersStats: null,
    stakingAccountsStats: null,
  };

  // Function to fetch all necessary stats
  const fetchAllStatsForAlerts = async () => {
    try {
      const [
        priceStatsResponse,
        holdersStatsResponse,
        volumeStatsResponse,
        stakingBalanceStatsResponse,
        stakingStatsResponse,
        raydiumPoolStatsResponse,
        stakingSummariesStatsResponse,
        tokenHoldersStatsResponse,
        stakingAccountsStatsResponse,
      ] = await Promise.all([
        alertGetNosanaPriceStats(),
        alertGetNosanaHoldersStats(),
        alertGetNosanaStats(), // Assuming this corresponds to volumeStats
        alertGetStakingBalanceStats(),
        alertGetStakingDappStats(),
        alertGetRaydiumPoolStats(),
        alertGetStakingSummariesStats(),
        alertGetTokenHoldersStats(),
        alertGetStakingSummariesAccountsStats(),
      ]);

      // Log API responses for debugging
      // console.log('priceStatsResponse.data:', priceStatsResponse.data);
      // console.log('holdersStatsResponse.data:', holdersStatsResponse.data);
      // console.log('volumeStatsResponse.data:', volumeStatsResponse.data);
      // console.log('stakingBalanceStatsResponse.data:', stakingBalanceStatsResponse.data);
      // console.log('stakingStatsResponse.data:', stakingStatsResponse.data);
      // console.log('raydiumPoolStatsResponse.data:', raydiumPoolStatsResponse.data);
      // console.log('stakingSummariesStatsResponse.data:', stakingSummariesStatsResponse.data);
      // console.log('tokenHoldersStatsResponse.data:', tokenHoldersStatsResponse.data);
      // console.log('stakingAccountsStatsResponse.data:', stakingAccountsStatsResponse.data);

      // Assign data to cachedData with checks
      if (priceStatsResponse.data && priceStatsResponse.data.stats) {
        cachedData.priceStats = priceStatsResponse.data.stats;
      } else {
        console.warn('priceStatsResponse.data.stats is undefined.');
        // console.log('priceStatsResponse.data:', priceStatsResponse.data);
      }

      if (holdersStatsResponse.data && holdersStatsResponse.data.stats) {
        cachedData.holdersStats = holdersStatsResponse.data.stats;
      } else {
        console.warn('holdersStatsResponse.data.stats is undefined.');
        // console.log('holdersStatsResponse.data:', holdersStatsResponse.data);
      }

      if (volumeStatsResponse.data && volumeStatsResponse.data.stats) {
        cachedData.volumeStats = volumeStatsResponse.data.stats;
      } else {
        console.warn('volumeStatsResponse.data.stats is undefined.');
        // console.log('volumeStatsResponse.data:', volumeStatsResponse.data);
      }

      if (stakingBalanceStatsResponse.data && stakingBalanceStatsResponse.data.stats) {
        cachedData.stakingBalanceStats = stakingBalanceStatsResponse.data.stats;
      } else {
        console.warn('stakingBalanceStatsResponse.data.stats is undefined.');
        // console.log('stakingBalanceStatsResponse.data:', stakingBalanceStatsResponse.data);
      }

      if (stakingStatsResponse.data && stakingStatsResponse.data.stats) {
        cachedData.stakingStats = stakingStatsResponse.data.stats;
      } else {
        console.warn('stakingStatsResponse.data.stats is undefined.');
        // console.log('stakingStatsResponse.data:', stakingStatsResponse.data);
      }

      if (raydiumPoolStatsResponse.data && raydiumPoolStatsResponse.data.stats) {
        cachedData.raydiumPoolStats = raydiumPoolStatsResponse.data.stats;
      } else {
        console.warn('raydiumPoolStatsResponse.data.stats is undefined.');
        // console.log('raydiumPoolStatsResponse.data:', raydiumPoolStatsResponse.data);
      }

      if (stakingSummariesStatsResponse.data && stakingSummariesStatsResponse.data.stats) {
        cachedData.stakingSummariesStats = stakingSummariesStatsResponse.data.stats;
      } else {
        console.warn('stakingSummariesStatsResponse.data.stats is undefined.');
        // console.log('stakingSummariesStatsResponse.data:', stakingSummariesStatsResponse.data);
      }

      if (tokenHoldersStatsResponse.data && tokenHoldersStatsResponse.data.stats) {
        cachedData.tokenHoldersStats = tokenHoldersStatsResponse.data.stats;
      } else {
        console.warn('tokenHoldersStatsResponse.data.stats is undefined.');
        // console.log('tokenHoldersStatsResponse.data:', tokenHoldersStatsResponse.data);
      }

      if (stakingAccountsStatsResponse.data && stakingAccountsStatsResponse.data.stats) {
        cachedData.stakingAccountsStats = stakingAccountsStatsResponse.data.stats;
      } else {
        console.warn('stakingAccountsStatsResponse.data.stats is undefined.');
        // console.log('stakingAccountsStatsResponse.data:', stakingAccountsStatsResponse.data);
      }

      // console.log('Fetched all stats for alerts:', cachedData);
    } catch (error) {
      console.error('Error fetching stats for alerts:', error);
    }
  };

  useEffect(() => {
    // Function to request notification permission on mount
    const requestNotificationPermission = async () => {
      if ('Notification' in window && Notification.permission !== 'granted') {
        try {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            // console.log('Notification permission granted.');
          } else if (permission === 'denied') {
            console.warn('Notification permission denied.');
          }
        } catch (error) {
          console.error('Error requesting notification permission:', error);
        }
      }
    };

    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const performAlertCheck = async () => {
      await fetchAllStatsForAlerts();
      await checkAlerts(cachedData);
    };

    // Initial check
    performAlertCheck();

    // Set up periodic checks
    const ALERT_CHECK_INTERVAL = 60 * 1000; // 60 seconds
    const intervalId = setInterval(performAlertCheck, ALERT_CHECK_INTERVAL);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <DarkModeProvider>
      <ShareModalProvider> {/* Wrap with ShareModalProvider */}
        <Router>
          <Layout>
            {/* ToastContainer should be placed inside Layout to be accessible across all pages */}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme={darkMode ? 'dark' : 'light'} // Set theme based on darkMode
            />
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/nosana-prices" element={<NosanaPricesPage />} />
                <Route path="/nosana-holders" element={<NosanaHoldersPage />} />
                <Route path="/nosana-volume" element={<NosanaVolumePage />} />
                <Route path="/staking-dapp" element={<StakingDappPage />} />
                <Route path="/nos-staked" element={<StakingBalancePage />} />
                <Route path="/wallet-distributions" element={<WalletAnalysisPage />} />
                <Route path="/raydium-pool" element={<RaydiumPoolPage />} />
                <Route path="/nos-staked-details" element={<StakingSummariesPage />} />
                <Route path="/stakers-unstakers" element={<StakingSummariesAccountsPage />} /> {/* New Route */}
                <Route path="/richlist" element={<RichListPage />} />
                <Route path="/sql-data" element={<SqlDataPage />} /> {/* New Route */}
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <AdminPage />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      </ShareModalProvider>
    </DarkModeProvider>
  );
};

export default App;
