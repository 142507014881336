import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-200 flex flex-col">
      {/* Navbar with dark mode toggle */}
      <Navbar />
      {/* Main Content */}
      <main className="flex-grow w-full px-0 py-4 sm:px-4 lg:px-16 xl:px-24 2xl:px-32">
        {children}
      </main>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Layout;
